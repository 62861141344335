import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import "./plugins/axios";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import "./assets/css/tailwind.css";
import "flowbite";
import "../node_modules/flowbite-vue/dist/index.css";
import "aos/dist/aos.css"; // Import file CSS AOS
import AOS from "aos"; // Import AOS
import "typeface-poppins";

library.add(fas);
library.add(faWhatsapp);

const app = createApp(App);
app.use(router);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");

AOS.init();
