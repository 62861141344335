export default {
  data() {
    return {
      userData: null,
      userId: null,
      userFullName: null,
      userName: null,
      userLevel: null,
      userAccess: null,
      userToken: null,
      userPhoto: "../assets/img/user.png",
    };
  },
  created() {
    const userData = localStorage.getItem("userData");
    if (userData) {
      this.userData = JSON.parse(userData);
      this.userId = this.userData.id;
      this.userFullName = this.userData.nama;
      this.userName = this.userData.username;
      this.userLevel = this.userData.level;
      this.userAccess = this.userData.hak_akses;
      this.userToken = this.userData.token;
      // this.userPhoto = this.userData.foto;
    }
  },
};
